import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-redirect',
  template: ''
})
export class RedirectComponent implements OnInit {
  constructor(private route: ActivatedRoute,
                private router: Router) {}

  sub: any;            

  ngOnInit() {
    this.sub = this.route
      .data
      .subscribe(v => {
        let redirectToPath = _.get(v, "redirectTo", "");
        if(redirectToPath != "") {
            this.router.navigate([redirectToPath]);
        }
      });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}