import { Injectable } from '@angular/core';
import {
 HttpRequest,
 HttpHandler,
 HttpEvent,
 HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import * as _ from 'lodash';

@Injectable()
export class HttpHeadersInterceptor implements HttpInterceptor {

 constructor() {}

 intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    var clientId = sessionStorage.getItem('clientId');

    if(clientId && clientId != null) {
      var modifiedRequest = request.clone({
        headers: request.headers.set("x-optum-api-key", clientId)
      });

      return next.handle(modifiedRequest)
    }
    
    return next.handle(request);
  }
}
