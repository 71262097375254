<div class="nav-header">
  <div class="grid flex flex-wrap justify-content-between px-5">
    <div class="flex flex-wrap col">
      <div>
        <a routerLink="/payment/payment-new"> <img
          src="./assets/images/UShealth-logo.svg"
          alt="image"
          width="120"
          height="50"/>
        </a>
      </div>
    </div>
    <div class="col flex justify-content-end flex-wrap" *ngIf="profile">      
      <span class="mr-2 text-sm text-color pt-1 font-semibold">{{ today }}</span>
      <span>
        <i class="pi pi-user text-xl"></i>
      </span>
      <!-- <span class="ml-2">
        <p-button icon="pi pi-times" [rounded]="true" [text]="true" severity="danger" (click)="logout()"></p-button>
      </span> -->
    </div>
  </div>
</div>
