export class DATE_TIME_FORMAT {
    public static ScheduleDate = "YYYY-MM-DD";
    public static LongDate = "dddd, MMMM D, YYYY";
    public static Standard = "MM/DD/YYYY";
    public static StandardDash = "MM-DD-YYYY";
    public static StandardFullMonth = "MMM DD YYYY";
    public static ShortForm = "MM/DD";
    public static ShortFormFullMonth = "MMM DD"
    public static ScheduleTime = "h:mm A";
}

export class WEEK_DAYS {
    public static SUNDAY = "Sunday";
    public static MONDAY = "Monday";
    public static TUESDAY = "Tuesday";
    public static WEDNESDAY = "Wednesday";
    public static THURSDAY = "Thursday";
    public static FRIDAY = "Friday";
    public static SATURDAY = "Saturday";
}