import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import * as _ from 'lodash';
import { UserType } from "../components/models/enums/auth-enums";
import { AuthService } from "../services/auth/auth-service.service";
import { TransactionTokenGuard } from "src/app/shared/guards/transaction-token.guard";
import { QueryParameters } from "src/app/reschedule/models/enums";

// this overrides the msalGuard plus additional logic for agents and applicants
export const AuthenticationGuard: CanActivateFn = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    let authService = inject(AuthService);
    let transactionTokenGuard = inject(TransactionTokenGuard);
    let router = inject(Router);

    let userType = _.get(route.queryParams, QueryParameters.USERTYPE, "");

    if(_.isEmpty(userType) || userType === UserType.VERIFIER) {
        return authService.processVerifierAccount(route);
    }
    else if(userType == UserType.AGENT) {
        return authService.processAgentAuthentication();
    }
    else if(userType == UserType.APPLICANT) {
        return transactionTokenGuard.canActivate(route, state);
    }
    else {
        return router.parseUrl('/not-authorized');
    }
};