import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavHeaderComponent } from './shared/components/nav-header/nav-header.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from  '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { PaymentModule } from './payment/payment.module';
import { NgIdleModule } from '@ng-idle/core';
import { DataMockingInterceptor } from './shared/data-mocking-interceptor';
// import { ToastComponent } from './shared/components/toast/toast.component';
import { SafePipeModule } from 'safe-pipe';
import { HttpMessageInterceptor } from './shared/http-message-interceptor';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PrimengModule } from './primeng/primeng.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { HttpHeadersInterceptor } from './shared/http-header-interceptor';
@NgModule({
  declarations: [
    AppComponent,
    NavHeaderComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    DashboardModule,
    HttpClientModule,
    NgIdleModule.forRoot(),
    PaymentModule,
    PrimengModule,
    RouterModule,
    SafePipeModule,
    SharedModule
  ],
  providers: [
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: DataMockingInterceptor,
    //   multi: true,
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpHeadersInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpMessageInterceptor,
      multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
