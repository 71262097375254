import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { PaymentApiService } from '../../payment/payment-api.service';
import * as _ from 'lodash';

/**
 * Authorization Guard
 */
@Injectable({
  providedIn: 'root',
})
export class TransactionTokenGuard {
  constructor(
    private router: Router,
    private paymentService: PaymentApiService
  ) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {

    let self = this;

    var transactionId = _.get(next, "params.transactionId");
    var token = _.get(next, "params.token");

    sessionStorage.setItem('transactionId', transactionId);
    sessionStorage.setItem('token', token);

    //NOTE: this will need to be able to identify the current userType so that it can use appropriate service to verify user.

    var observable$ = self.paymentService.verifyUser(transactionId, token);
    var data = await firstValueFrom(observable$);

    if (data && data.hasOwnProperty('error')) {
      return this.router.parseUrl('/invalid-token');
    }
    else {
      sessionStorage.setItem('clientId', data.clientId);
      return Promise.resolve(true);
    }
  }
}
