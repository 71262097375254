import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-extra-info',
  templateUrl: './extra-info.component.html',
  styleUrls: ['./extra-info.component.scss']
})
export class ExtraInfoComponent implements OnInit {
    @Input() agentInfo: any;
    @Input() productInfo: any;

    extraInfoClass: string = "extra-info";
    constructor(private router: Router) {}

    ngOnInit() { }
}
