import { Component, OnInit } from '@angular/core';
import { SpinnerService } from '../../services/spinner.service';
import { UtilityService } from '../../services/utility.service';

@Component({
  selector: 'app-main',
  templateUrl: './app-main.component.html',
  styleUrls: ['./app-main.component.scss']
})
export class AppMainComponent implements OnInit {
  showSpinner: boolean;
  constructor(
    private spinnerService: SpinnerService,
    private utilityService: UtilityService
  ) {}

  get isIframe() {
    return this.utilityService.isIFrameMode();   
  }

  ngOnInit() {
    this.spinnerService.spinnerVisible$.subscribe(val => this.showSpinner = val);
  }
}
