import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-fail-payment',
  templateUrl: './fail-payment.component.html',
  styleUrls: ['./fail-payment.component.scss']
})
export class FailPaymentComponent implements OnInit {

  transactionId: string = sessionStorage.getItem('transactionId');
  token: string = sessionStorage.getItem('token');

  constructor(private router: Router) {  }

  ngOnInit() {
  }
  gotoHome(){
    console.log(this.router.navigate(['/payment/payment-new/'], {  queryParams: {  transactionId: this.transactionId, token: this.token } }));
  }

}
