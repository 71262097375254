
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ModalComponent } from './components/modal/modal.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NoopInterceptor } from './noop-interceptor';
import { AppMainComponent } from './components/app-main/app-main.component';
import { PrimengModule } from '../primeng/primeng.module';
import { RouterModule } from '@angular/router';
import { ApplicantInfoComponent } from './components/applicant-info/applicant-info.component';
import { ExtraInfoComponent } from './components/extra-info/extra-info.component';
import { AuthMainComponent } from './components/auth-main/auth-main.component';
import { MessageService } from 'primeng/api';
import { EmptyComponent } from './components/empty/empty-component';
import { RedirectComponent } from './components/redirect/redirect-component';
import { InvalidTokenComponent } from './components/invalid-token/invalid-token.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    PrimengModule
  ],
  exports: [
    ApplicantInfoComponent,
    ExtraInfoComponent,
    EmptyComponent,
    ModalComponent,
    PageNotFoundComponent,
    RedirectComponent
  ],
  declarations: [
    ApplicantInfoComponent,
    AppMainComponent,
    AuthMainComponent,
    ExtraInfoComponent,
    EmptyComponent,
    InvalidTokenComponent,
    ModalComponent,
    PageNotFoundComponent,
    RedirectComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NoopInterceptor,
      multi: true,
    },
    MessageService
   ]
})
export class SharedModule {}
