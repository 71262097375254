import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HandledHttpClient } from './../shared/handledHttpClient';
import { ApplicationInfo } from './application-model';

@Injectable({
  providedIn: 'root',
})
export class ApplicationApiService {
  private baseUrl: string;

  constructor(private http: HandledHttpClient) {
    this.baseUrl = environment.config.apis.vcall;
  }

  getApplicationInfo(applicationId: number): Observable<ApplicationInfo> {
    return this.http.get<ApplicationInfo>(this.baseUrl + `/vcall/v1/application/${applicationId}`);
  }
}
