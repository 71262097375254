import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-applicant-info',
  templateUrl: './applicant-info.component.html',
  styleUrls: ['./applicant-info.component.scss']
})
export class ApplicantInfoComponent implements OnInit {
    @Input() applicantInfo: any;
    @Input() contactInfo: any;
    applicantInfoClass: string = "applicant-info";
    constructor(private router: Router) {}

    ngOnInit() { }
}
