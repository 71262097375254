import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotAuthorizedComponent } from './shared/components/not-authorized/not-authorized.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { AppMainComponent } from './shared/components/app-main/app-main.component';
import { PaymentMainComponent } from './payment/payment-main/payment-main.component';
import { CreditCardMainComponent } from './credit-card-payment/credit-card-main/credit-card-main.component';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { AuthenticationGuard } from './shared/guards/authentication.guard';
import { LookupDataResolver } from './shared/guards/lookupdata.resolver';
import { RescheduleMainComponent } from './reschedule/reschedule-main/reschedule-main.component';
import { InvalidTokenComponent } from './shared/components/invalid-token/invalid-token.component';

const routes: Routes = [
  {
    path: 'dashboard',
    component: AppMainComponent,
    loadChildren: () =>
      import('./dashboard/dashboard.module')
      .then((m) => m.DashboardModule)
  },
  {
    path: 'payment',
    component: PaymentMainComponent,
    loadChildren: () =>
      import('./payment/payment.module')
      .then((m) => m.PaymentModule),

  },
  {
    path: 'credit-card',
    component: CreditCardMainComponent,
    loadChildren: () =>
      import('./credit-card-payment/credit-card-payment.module')
      .then((m) => m.CreditCardPaymentModule)
  },
  {
    path: 'reschedule',
    component: RescheduleMainComponent,
    loadChildren: () =>
      import('./reschedule/reschedule.module')
      .then((m) => m.RescheduleModule),
    canActivate: [AuthenticationGuard],
    resolve: { lookupDataIsReady: LookupDataResolver}
  },
  {
    path: 'playground',
    component: AppMainComponent,
    loadChildren: () => 
      import('./playground/playground.module')
      .then((m) => m.PlaygroundModule)
  },
  {
    path: 'auth',
    component: MsalRedirectComponent,
  },
  {
    path: 'not-authorized',
    component: NotAuthorizedComponent,
  },
  {
    path: 'invalid-token',
    component: InvalidTokenComponent,
  },
  {
    path: '404', component: PageNotFoundComponent
  },
  {
    path: '**', redirectTo: '/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
