<p-card
    [styleClass]="applicantInfoClass">
    <!-- <div class="applicant-info pl-3 pt-2 pb-2"> -->
    <div class="px-3">
        <div class="grid">
            <div class="col-12 md:col-6 lg:col-3">
                <span><i class="pi pi-calendar-times mr-2"></i></span><span>{{applicantInfo?.birthDate}}</span>
            </div>
            <div class="col-12 md:col-6 lg:col-4">
                <span><i class="pi pi-user mr-2"></i></span><span>{{applicantInfo?.gender}} | {{applicantInfo?.height}} | {{applicantInfo?.weight}} LBS</span>
            </div>
        </div>
        <div class="grid mt-2">
            <div class="col-12 md:col-6 lg:col-3">
                <span><i class="pi pi-phone mr-2"></i></span><span>{{contactInfo?.homePhone}}</span>
            </div>
            <div class="col-12 md:col-6 lg:col-3">
                <span><i class="pi pi-mobile mr-2"></i></span><span>{{contactInfo?.mobilePhone}}</span>
            </div>
            <div class="col-12 md:col-6">
                <span><i class="pi pi-home mr-2"></i></span><span>{{contactInfo?.streetAddress}}, {{contactInfo?.city}}, {{contactInfo?.state}} - {{contactInfo?.zipCode}}</span>
            </div>
        </div>
    </div>
</p-card>
