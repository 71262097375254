<p-toast></p-toast>
<!-- <p-messages></p-messages> -->
<app-nav-header *ngIf="showHeader"></app-nav-header>
<div class="container">
  <router-outlet></router-outlet>
  <!-- <app-toasts></app-toasts> -->
</div>
<div class="bg-layout"></div>
<!-- 
<app-modal
  [open]="openModal"
  [buttonsConfig]="{
    modalMode: 'msg',
    submitButtonName: 'Close'
  }"
>
  <div title>
    <h4 class="modal-title" id="modal-basic-title">
      <span> Header</span>
    </h4>
  </div>
  <div body>
   Sample
  </div>
</app-modal> -->

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
  [fullScreen] = "true"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>