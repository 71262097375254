import { Component, CUSTOM_ELEMENTS_SCHEMA, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentApiService } from '../payment-api.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BillingInfo, SelfServiceParams, TransactionStatus } from '../payment-model';
import { ToastService } from 'src/app/shared/services/toast/toast.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs/internal/Subscription';
import { firstValueFrom, lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-new-payment',
  templateUrl: './new-payment.component.html',
  styleUrls: ['./new-payment.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  standalone: true
})
export class NewPaymentComponent implements OnInit, OnDestroy {

  trustUrl: SafeResourceUrl;
  transactionId: string;
  subscriptions = Array<Subscription>();
  refreshInterval: ReturnType<typeof setInterval> | undefined;
  billingInfo: BillingInfo;

  constructor(
    private route: Router,
    private activatedRoute: ActivatedRoute,
    private paymentService: PaymentApiService,
    private sanitizer: DomSanitizer,
    private toastService: ToastService,
    private spinnerService: NgxSpinnerService,
  ) {
    this.transactionId = new SelfServiceParams(this.activatedRoute.snapshot.paramMap).transactionId;
  }

  async ngOnInit() {
    this.spinnerService.show();
    await this.checkGetBillingInfo();
    if (this.billingInfo && (this.billingInfo.transactionStatusId === TransactionStatus.Pending || this.billingInfo.transactionStatusId === TransactionStatus.Created)) {
      this.subscriptions.push(this.paymentService.getBillingParameters(this.transactionId).subscribe((resp) => {
        if (resp) {
          this.trustUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
            resp.src +
            '?UshgAppName=' + resp.ushgAppName +
            '&UshgAppKey=' + resp.ushgAppKey +
            '&UshgAppUser=' + resp.ushgAppUser +
            '&UshgUseCase=' + resp.ushgUseCase +
            '&UshgCompanyCode=' + resp.ushgCompanyCode +
            '&UshgReferenceIdType=' + resp.ushgReferenceIdType +
            '&UshgReferenceId=' + resp.ushgReferenceId +
            '&Amount=0' +
            '&ResponseUrl=' + resp.respUrl)
        }
      })
      );
    }
    this.spinnerService.hide();
    this.refreshInterval = setInterval(() => {
      this.checkGetBillingInfo();
    }, 5000);
  }


  async checkGetBillingInfo() {
    const source$ = this.paymentService.getBillingInfo(this.transactionId);
    await lastValueFrom(source$).then((resp) => {
      this.billingInfo = resp;
      if (resp && (resp.transactionStatusId === TransactionStatus.Closed)) {
        this.toastService.success('', 'Credit card details updated success.');
        clearInterval(this.refreshInterval);
        this.route.navigate(['/payment/payment-success']);
      } else if (resp === null || resp && (resp.transactionStatusId === TransactionStatus.Failed)) {
        this.toastService.error('', 'Credit card details updated fail.');
        clearInterval(this.refreshInterval);
        this.route.navigate(['/payment/payment-fail']);
      }
    })
  }

  ngOnDestroy() {
    this.subscriptions.map((s) => s.unsubscribe());
  }

}
