import { Component, OnDestroy, OnInit} from '@angular/core';
import { Subscription } from 'rxjs';
import { UserProfile } from '../models/auth/user-profile';
import { UserIdentityService } from '../../services/auth/user-identity.service';

@Component({
  selector: 'app-nav-header',
  templateUrl: './nav-header.component.html',
  styleUrls: ['./nav-header.component.scss'],
})
export class NavHeaderComponent implements OnInit, OnDestroy {
  constructor(
    private userIdentityService: UserIdentityService
  ) { }
  today: string = new Date().toDateString();
  subscription: Subscription;
  profile: UserProfile;

  ngOnInit() {
    this.subscription = this.userIdentityService
                            .userProfile
                            .subscribe((s) => {
                              this.profile = s;
                            });
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
