import * as moment from "moment";
import { DATE_TIME_FORMAT } from "src/app/shared/services/date-time/date-time.models";
export interface AppointmentOptions {
    timeZoneId: string;
    timeZoneName: string;
    languageId: string;
    languageName: string;
    utcOffset: number;
}

export class Language {
    id: string;
    label: string;
    order: number;
    externalCode: string;
    is_Active: boolean;

    constructor(data: any) {
        this.id = data.id.toString();
        this.label = data.label;
        this.order = data.order;
        this.externalCode = data.externalCode;
        this.is_Active = data.is_Active;
    }
}

export class AppointmentInfo {
    date: string;
    timeZoneId: string;
    timeZoneName: string;
    languageId: string;
    languageName: string;
    timeStart: string;
    timeEnd: string;

    constructor(data: any) {
        Object.assign(this, data);
    }
}

export class RescheduleData {

    /*Initially comes from queryparams*/
    appId: string = "";
    appointmentId: string = "";
    mode: string = "";
    userName: string = "";
    resourceId: string = "";
    userType: string = "";
    timeZoneName: string = "";
    token: string = "";
    /*Initially comes from queryparams*/
    
    languageName: string = "";
    date: string = "";

    // this number value but in string used to populate the dropdowns
    languageId: string = "";
    timeZoneId: string = "";

    constructor(data: any) {
        Object.assign(this, data);
    }
}
export class TimeZone {
    id: string;
    identifier: string;
    standardName: string;
    displayName: string;
    utcOffset: number;
    supportsDaylightSaving: boolean;
    isDaylightSaving: boolean;
    abbreviation: string;
    isActive: boolean;

    constructor(data: any) {
        this.id = data.id.toString();
        this.identifier = data.identifier;
        this.standardName = data.standardName;
        this.displayName = data.displayName;
        this.utcOffset = data.utcOffset;
        this.supportsDaylightSaving = data.supportsDaylightSaving;
        this.isDaylightSaving = data.isDaylightSaving;
        this.abbreviation = data.abbreviation;
        this.isActive = data.isActive === null ? true : false;
    }
}

export class HoursAvailabilityRequest {
    appId: string;
    date: string;
    languageId: string;
    timeZoneIdentifier: string;
    applicantTimeZoneIdentifier: string;

    constructor(data: any) {
        Object.assign(this, data);
    }
}

export class HoursAvailabilityRequest2 {
    appId: string;
    date: string;
    languageId: string;
    timeZoneToUseIdentifier: string;
    applicantTimeZoneIdentifier: string;
    applicantUtcOffset: number;
    utcOffsetConvertTo: number;

    constructor(data: any) {
        Object.assign(this, data);
    }
}
export class ConfirmCallRequest {
    appId: number;
    isRescheduling: boolean;
    scheduleId: number;
    languageId: number;
    selectedDate: string;
    appointmentId: number;
    verifieruserId: number;

    constructor(data: any) {
        Object.assign(this, data);
    }
}

export class CalendarDayAvailability {
    formattedCalendarDay: string;
    calendarDay: string;
    weekdayNumber: string;
    isUnavailable: boolean = true;

    get isAvailable() {
        return !this.isUnavailable;
    }

    constructor(data: any) {
        this.formattedCalendarDay = moment(data.calendarDay).format(DATE_TIME_FORMAT.ShortForm);
        this.calendarDay = data.calendarDay;
        this.weekdayNumber = data.weekdayNumber;
        this.isUnavailable = (data.isUnavailable === undefined || data.isUnavailable === null) ? true : data.isUnavailable;
    }
}

export class CalendarWeekAvailability {
    Sunday: CalendarDayAvailability;
    Monday: CalendarDayAvailability;
    Tuesday: CalendarDayAvailability;
    Wednesday: CalendarDayAvailability;
    Thursday: CalendarDayAvailability;
    Friday: CalendarDayAvailability;
    Saturday: CalendarDayAvailability;

    constructor(data: any) {
        this.Sunday = new CalendarDayAvailability(data.sunday);
        this.Monday = new CalendarDayAvailability(data.monday);
        this.Tuesday = new CalendarDayAvailability(data.tuesday);
        this.Wednesday = new CalendarDayAvailability(data.wednesday);
        this.Thursday = new CalendarDayAvailability(data.thursday);
        this.Friday = new CalendarDayAvailability(data.friday);
        this.Saturday = new CalendarDayAvailability(data.saturday);
    }
}
export class CalendarWeek {
    calendarWeek: CalendarWeekAvailability
    constructor(data: any) {
        this.calendarWeek = data.calendarWeek;
    }
}

export class DaySlotTime {
    scheduleId: number;
    checkInTIme: string;
    convertedCheckInTIme: string;
    checkOutTIme: string;
    convertedCheckOutTIme: string;
    convertedToTimezoneIdentifier: string;
    available: boolean;

    constructor(data: any) {
        this.scheduleId = data.scheduleId;
        this.checkInTIme = this.formatTime(data.checkInTIme);
        this.convertedCheckInTIme = this.formatDateTime(data.convertedCheckInTIme);
        this.checkOutTIme = this.formatTime(data.checkOutTIme);
        this.convertedCheckOutTIme = this.formatDateTime(data.convertedCheckOutTIme);
        this.convertedToTimezoneIdentifier = this.convertedToTimezoneIdentifier;
        this.available = data.available;
    }

    formatTime(time: string) {
        let dateTime = moment(`01/01/0001 ${time}`);
        return dateTime.format(DATE_TIME_FORMAT.ScheduleTime);
    }

    formatDateTime(dateTime: string) {
        let data = moment(dateTime);
        return data.format(DATE_TIME_FORMAT.ScheduleTime);
    }
}