<p-card
    [styleClass]="extraInfoClass">
    <div class="px-3">
        <div class="grid">
            <div class="col-12">
                <label class="info-label">Agent Details</label>
                <div>
                    <span><i class="pi pi-shield info-text mr-1"></i></span><span class="info-text mr-1">{{agentInfo?.firstName}} {{agentInfo?.lastName}}</span><span class="agent-number">{{agentInfo?.agentNumbers}}</span>
                </div>
            </div>
            <div class="col-12 mt-3">            
                <label class="info-label">Product</label>
                <div>
                    <span class="info-text">{{productInfo?.description}}</span>
                </div>
            </div>
        </div>
    </div>
</p-card>