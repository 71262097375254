import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { AppMode } from './reschedule/models/enums';
import { UtilityService } from './shared/services/utility.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app';
  openModal= false;
  appMode: string = "";

  constructor(
    private route: ActivatedRoute,
    private utilityService: UtilityService
  ) {}

  get showHeader() {
    return !this.utilityService.isIFrameMode();
  }

  ngOnInit() { 
    setTimeout(()=>{
      this.openModal= true;
    }, 1000);
  }
}
