import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { ToastService } from 'src/app/shared/services/toast/toast.service';
import { ResponseDeciderService } from './responseDecider/responseDecider.service';
/**
 * Auth interceptor
 */
@Injectable()
export class HttpMessageInterceptor implements HttpInterceptor {
  constructor(
    private toaster: ToastService,
    private deciderService: ResponseDeciderService,
  ) {}
  /**
   * interceptor
   * @param req http request
   * @param next http handler
   */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const success: string = req.headers.get('x-success-message');
    const error: string = req.headers.get('x-error-message');
    const deciderKey: string = req.headers.get('x-response-decider-key');
    const dontLog: string = req.headers.get('x-dont-log');

    return next.handle(req).pipe(
      tap((evt) => {
        if (evt instanceof HttpResponse) {
          if (deciderKey) {
            if (this.deciderService.IsError(deciderKey, evt)) {
              this.toaster.error(null, error);
            } else if (success !== 'undefined' && success !== null) {
              this.toaster.success(null, success);
            }
          } else if (evt.ok && success) {
            this.toaster.success(null, success);
          }
        }
      }),
      catchError((err: any) => {
        if (dontLog === 'true') {
          return of(err);
        }

        if (err instanceof HttpErrorResponse && err.status !== 200) {
          try {
            this.toaster.error(null, error);
          } catch (e) {
            this.toaster.error(null, error);
          }
          return of(new HttpResponse({ status: 500, body: err }));
        }
        // this is for APIs that do not return graceful json
        // if (success && err.status === 200) {
        if (err.status === 200) {
          if (success) {
            this.toaster.success(null, success);
          }
          return of(new HttpResponse({ status: 200, body: err.json }));
        }
        // this.logApiFailureException(req, error);
        return of(err);
      })
    );
  }
 
}
