import { RouterModule, Routes } from "@angular/router";
import { ApplicationLandingComponent } from "./application-landing/application-landing.component";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { PrimengModule } from "../primeng/primeng.module";
import { SharedModule } from '../shared/shared.module';

const routes: Routes =[
    {
        path: 'application/:transactionId/:token',
        component: ApplicationLandingComponent
    }
];

@NgModule({
    imports: [
      CommonModule,
      RouterModule.forChild(routes),
      PrimengModule,
      SharedModule
    ],
    declarations: [
      ApplicationLandingComponent
    ],
    providers: [],
  })
  export class DashboardModule {}