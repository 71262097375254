// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    name: "DEV",
    production: true,
    config: {
      apis: {
        nbc: 'https://nbc-gateway-dev.ushealthgroup.com',
        vcall: 'https://vcall-gateway-dev.ushealthgroup.com/api',
        callzone: 'https://vcall-gateway-dev.ushealthgroup.com/api/callzone/v1'
      },
    },
    authConfig: {
      tenant: 'cc7c826f-6444-4c0e-8314-83afb87c98ea',
      clientId: '20ede298-3cc2-44d0-8932-206a27e4694a',
      redirectUri: 'https://apss-dev.ushealthgroup.com/auth',
      postLogoutRedirectUri: 'https://apss-dev.ushealthgroup.com/logout',
      addedProtectedResourceMap: [
        {
          key: 'https://vcall-gateway-dev.ushealthgroup.com/api',
          list: ['api://b1346b42-b084-4cfa-b48f-57b606fc5704/Users.Execute'],
        },
        {
          key: 'https://nbc-gateway-dev.ushealthgroup.com',
          list: ['api://b1346b42-b084-4cfa-b48f-57b606fc5704/Users.Execute'],
        }
      ],
      cacheLocation: 'localStorage',
    },
    smc: {
      useSmc: true,
      referrer: 'https://messagecenter-dev.ushealthgroup.com/'
    }
  };
  
  /*
   * For easier debugging in development mode, you can import the following file
   * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
   *
   * This import should be commented out in production mode because it will have a negative impact
   * on performance if an error is thrown.
   */
  // import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
  