import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from "@angular/router";
import * as _ from 'lodash';

import { LookupDataService } from "../services/lookupdata/lookupdata.service";

export const LookupDataResolver: ResolveFn<boolean> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    let lookupDataService = inject(LookupDataService);
    
    return lookupDataService.initializeLookupData()
                            .then(() => {
                                return true;
                            });
};