<div class="application-landing">
    <div class="grid">
        <div class="col-6">
            <div><span class="primary-label">Primary</span></div>
            <div><span class="primary-applicant">{{primaryApplicant?.firstName}} {{primaryApplicant?.lastName}}</span></div>
            <div class="mt-5">
                <app-applicant-info
                    [applicantInfo]="primaryApplicant"
                    [contactInfo]="primaryApplicantContactInfo">
                </app-applicant-info>
            </div>
        </div>
        <div class="col-4">
            <div class="family-dependent pl-3 pt-2">
                <span class="dependent-label">Dependent</span>
                <div class="grid pt-2" *ngFor="let family of dependentFamily">                    
                    <div class="col-4" >
                        <span class="dependent-name">{{family?.firstName}} {{family?.lastName}}</span>
                    </div>
                    <div class="col-3">
                        <span><i class="pi pi-calendar-times dependent-icon mr-1"></i></span><span class="dependent-text">{{family?.birthDate}}</span>
                    </div>
                    <div class="col-5">
                        <span><i class="pi pi-user dependent-icon mr-1"></i></span><span class="dependent-text">{{family?.gender}} | {{family?.height}} | {{family?.weight}} LBS</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="mt-3 extra-card">
        <p-card [style]="{
            backgroundColor: '#FFFFFF',
            borderRadius: '16px',
            boxShadow: '0px -2px 37px #1C26311A'
        }">
            <div class="grid pl-5">
                <div class="col-2">
                    <div class="mb-1">
                        <span class="info-label">Agent Details</span>
                    </div>
                    <div>
                        <span><i class="pi pi-shield info-text mr-1"></i></span><span class="info-text mr-1">{{agent?.firstName}} {{agent?.lastName}}</span><span class="agent-number">{{agent?.agentNumbers}}</span>
                    </div>
                </div>
                <div class="col-2">
                    <div class="mb-1">
                        <span class="info-label">Product</span>
                    </div>
                    <div>
                        <span class="info-text">{{product?.description}}</span>
                    </div>
                </div>
            </div>
        </p-card>
    </div>

    <div class="mt-3 policy-card">
        <p-card [style]="{
            backgroundColor: '#FFFFFF',
            borderRadius: '16px',
            boxShadow: '0px -2px 37px #1C26311A'
        }">
            <div class="pl-5"><span class="policy-card-label">Policies</span></div>
            <p-carousel  [value]="policies" [numVisible]="3" [showIndicators]="false" [numScroll]="1"  [circular]="true" [responsiveOptions]="responsiveOptions">
                <ng-template let-policy pTemplate="item">
                    <div class="grid">
                        <div class="col-5 mt-4">
                            <div>
                                <span class="info-label">{{policy?.product}}</span>
                            </div>
                            <div>
                                <span class="text-xs text-color font-semibold">{{policy?.policyNumber}}</span>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </p-carousel>
        </p-card>
    </div>

    <div class="mt-3 task-card">
        <p-card [style]="{
            backgroundColor: '#FFFFFF',
            borderRadius: '16px',
            boxShadow: '0px -2px 37px #1C26311A'
        }">
            <div>
                <p-tag value="&nbsp; Tasks &nbsp;" [rounded]="true" [style]="{
                    backgroundColor: '#283E56'
                }"></p-tag>
            </div>
            <div class="grid mt-2 pl-5">
                <div class="col-3">
                    <p-card 
                        [style]="{
                            backgroundColor: '#FFFFFF',
                            borderRadius: '16px',
                            boxShadow: '0px -2px 37px #1C26311A'
                        }"
                    >
                        <div><span class="info-label">Update Credit Card Details</span></div>
                        <div><span class="info-text">You can update the details here</span></div>
                        <div class="mt-5"><p-button label="Update" size="small" (click)="navigateToPayment()"></p-button></div>
                    </p-card>
                </div>
            </div>
        </p-card>
    </div>
</div>