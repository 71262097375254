import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { lastValueFrom } from 'rxjs';
import { PaymentApiService } from 'src/app/payment/payment-api.service';
import { BillingInfo, SelfServiceParams } from 'src/app/payment/payment-model';
import { ApplicationApiService } from '../application-api.service';
import { ApplicationInfo } from '../application-model';
import * as _ from 'lodash';

@Component({
  selector: 'application-landing',
  templateUrl: './application-landing.component.html',
  styleUrls: ['./application-landing.component.scss']
})
export class ApplicationLandingComponent implements OnInit {
    transactionId: string;
    refreshInterval: ReturnType<typeof setInterval> | undefined;
    billingInfo: BillingInfo;
    applicationInfo: ApplicationInfo;
    responsiveOptions: any[] | undefined;
    token: string;

    get primaryApplicant() {
      return _.get(this.applicationInfo, 'primaryApplicant', null);
    }

    get primaryApplicantContactInfo() {
      return _.get(this.applicationInfo, 'contact', null);
    }

    get dependentFamily() {
      return _.get(this.applicationInfo, 'family', null);
    }

    get agent() {
      return _.get(this.applicationInfo, 'agent', null);
    }

    get product() {
      return _.get(this.applicationInfo, 'product', null);
    }

    get policies() {
      return _.get(this.applicationInfo, 'policyInformation', null);
    }
        
    constructor(private router: Router,
                private paymentService: PaymentApiService,
                private applicationService: ApplicationApiService,
                private activatedRoute: ActivatedRoute,
                private spinnerService: NgxSpinnerService)
    {
        this.transactionId = new SelfServiceParams(this.activatedRoute.snapshot.paramMap).transactionId;
        this.token = this.activatedRoute.snapshot.paramMap.get("token");
    }

    async ngOnInit() {
      this.spinnerService.show();

      await this.getApplicationInfo();

      this.spinnerService.hide();
    }

    async getApplicationInfo() {
      const source$ = this.paymentService.getBillingInfo(this.transactionId);
      await lastValueFrom(source$).then((resp) => {
        this.billingInfo = resp;
      })

      const application$ = this.applicationService.getApplicationInfo(this.billingInfo.appId);
      await lastValueFrom(application$).then((resp) => {
          this.applicationInfo = resp;
      })
    }

    navigateToPayment() {
      this.router.navigate([`/payment/payment-new/${this.transactionId}/${this.token}`]);
    }
}