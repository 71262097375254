import { Injectable } from "@angular/core";
import { AccountInfo } from "@azure/msal-browser";
import { CallZoneUser, UserProfile } from "../../components/models/auth/user-profile";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class UserIdentityService {
    /**
   * user roles
   */
  userRoles = [];
  /**
   * user profile
   */
  private profile: UserProfile;
  private user: CallZoneUser;

  userProfile: BehaviorSubject<UserProfile>;
  callZoneUser: BehaviorSubject<CallZoneUser>;
  Roles = new BehaviorSubject<string[]>(this.userRoles);
  /**
   * User profile service constructor
   */
  constructor() {
    this.userProfile = new BehaviorSubject<UserProfile>(null);
    this.callZoneUser = new BehaviorSubject<CallZoneUser>(null);
  }
  /**
   * Clears user profile and roles
   */
  clearProfile() {
    this.profile = null;
    this.userRoles = [];
    this.userProfile.next(this.profile);
  }

  /**
   * @param newProfile set the new profile info
   * @description set the user profile
   */
  setUserProfile(newProfile: UserProfile) {
    this.profile = newProfile;
    this.setRoles(this.profile.roles);
    this.userProfile.next(this.profile);
  }

  /**
   * @description get the user profile
   */
  getUserProfile() {
    return this.profile;
  }

  /**
   * @param user user
   * @description set the user profile in callZone
   */
  setCallzoneUser(user: CallZoneUser) {
    this.user = user;
    this.callZoneUser.next(user);
  }

  /**
   * @description get the callZone user information
   * @returns user data
   */
  getCallzoneUser(): CallZoneUser {
    return this.user;
  }

  /**
   * @param roles set the user roles
   * @description set the user roles
   */
  setRoles(roles: string[]) {
    this.userRoles = roles;
    this.profile.roles = this.userRoles;
    this.Roles.next(this.userRoles);
    this.userProfile.next(this.profile);
  }

  /**
   * @description get user roles
   * @returns user roles
   */
  getRoles() {
    return this.userRoles;
  }

  async loadRoles(account: AccountInfo) {
    if (this.userRoles && this.userRoles.length > 0) {
      return this.userRoles;
    }

    this.userRoles = [];
    const groups: [] = account.idTokenClaims['groups'] as [];
    groups.map((g) => this.userRoles.push(g));

    this.Roles.next(this.userRoles);

    this.profile.roles = this.userRoles;
    this.userProfile.next(this.profile);

    return this.userRoles;
  }

  /**
   * Determines if current user has a the specified application role
   * @param roleName Name of the user role
   */
  isInRole(roleName: string): boolean {
    if (roleName !== null && roleName !== '') {
      roleName = roleName.trim();

      if (this.userRoles !== null && this.userRoles !== undefined) {
        return this.userRoles.findIndex((role) => role === roleName) !== -1;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}