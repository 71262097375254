import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SpinnerService } from 'src/app/shared/services/spinner.service';

@Component({
  selector: 'app-payment-main',
  templateUrl: './payment-main.component.html',
  styleUrls: ['./payment-main.component.scss']
})
export class PaymentMainComponent implements OnInit {
  showSpinner: boolean;
  constructor(private spinnerService: SpinnerService) {}
  ngOnInit() {
    this.spinnerService
        .spinnerVisible$
        .subscribe(val => this.showSpinner = val);
  }
}
