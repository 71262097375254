import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FailPaymentComponent } from './fail-payment/fail-payment.component';
import { NewPaymentComponent } from './new-payment/new-payment.component';
import { SuccessPaymentComponent } from './success-payment/success-payment.component';
import { ReturnPaymentComponent } from './return-payment/return-payment.component';
import { SharedModule } from '../shared/shared.module';
import { SafePipeModule } from 'safe-pipe';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DataMockingInterceptor } from '../shared/data-mocking-interceptor';
import { TransactionTokenGuard } from '../shared/guards/transaction-token.guard';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PrimengModule } from '../primeng/primeng.module';
import { PaymentMainComponent } from './payment-main/payment-main.component';

const routes: Routes = [
    {
      path: 'payment-new/:transactionId/:token',
      component: NewPaymentComponent,
      canActivate:[TransactionTokenGuard]
    },
    {
      path: 'payment-return',
      component: ReturnPaymentComponent
    },
    {
      path: 'payment-success',
      component: SuccessPaymentComponent
    },
    {
      path: 'payment-fail',
      component: FailPaymentComponent
    }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    SafePipeModule,
    NgxSpinnerModule,
    PrimengModule
  ],
  exports: [NgxSpinnerModule],
  declarations: [
    SuccessPaymentComponent,
    PaymentMainComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DataMockingInterceptor,
      multi: true,
    }
  ],
})
export class PaymentModule {}
