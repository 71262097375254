import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { MenubarModule } from 'primeng/menubar';
import { MessagesModule } from 'primeng/messages';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TagModule } from 'primeng/tag';
import { ToastModule } from 'primeng/toast';



@NgModule({
  imports: [
    ButtonModule,
    CardModule,
    CarouselModule,
    DividerModule,
    DropdownModule,
    MenubarModule,
    MessagesModule,
    ProgressSpinnerModule,
    TagModule,
    ToastModule
  ],
  declarations: [],
  exports:[
    ButtonModule,
    CardModule,
    CarouselModule,
    DividerModule,
    DropdownModule,
    MenubarModule,
    MessagesModule,
    ProgressSpinnerModule,
    TagModule,
    ToastModule
  ]
})
export class PrimengModule { }
