import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HandledHttpClient } from 'src/app/shared/handledHttpClient';
import * as _ from 'lodash';

import { Language, TimeZone } from 'src/app/reschedule/models/appointment';
import { UtilityService } from '../utility.service';

@Injectable({
    providedIn: 'root'
})
export class LookupDataService {
  private baseUrl: string;
  languageList: Language[] = []
  timeZoneList: TimeZone[] = [];
  applicantTimeZone: string;
  defaultLanguage: string = "0";
  defaultTimeZone: string = "0";

  constructor(
    private http: HandledHttpClient,
    private utilityService: UtilityService) {
    this.baseUrl = environment.config.apis.callzone;
  }

  async initializeLookupData() {
    this.languageList = await this.getLanguages();
    this.timeZoneList = await this.getTimezones();
    this.setDefaultValues();
  }

  async getLanguages(): Promise<Language[]>{
    let self = this;
    let languages: Language[] = [];

    let observable$ = this.http.get(this.baseUrl + `/schedule/languages`, { addToken: true});

    await firstValueFrom(observable$)
          .then((result: any) => {
            self.utilityService.checkHttpErrorResponse(result);
            languages = _.map(result, (y) => new Language(y));
          })
          .catch(error => {
            self.utilityService.showApiError(error.message);
          });

    return languages;
  }

  async getTimezones(): Promise<TimeZone[]>{
    let self = this;
    let timeZones: TimeZone[] = [];

    let observable$ = this.http.get(this.baseUrl + `/schedule/timezones`, { addToken: true});

    await firstValueFrom(observable$)
          .then((result:any) => {
            self.utilityService.checkHttpErrorResponse(result);            
            var data = _.map(result, (y) => new TimeZone(y));

            timeZones = _.filter(data, (x: TimeZone) => x.isActive === true);
          })
          .catch(error => {
            self.utilityService.showApiError(error.message);
          });

    return timeZones;
  }

  setDefaultValues() {
    let firstLanguageItem = _.first(this.languageList);
    if(firstLanguageItem) {
      this.defaultLanguage = firstLanguageItem.id;
    }

    let firstTimeZoneItem = _.first(this.timeZoneList);
    if(firstTimeZoneItem) {
      this.defaultTimeZone = firstTimeZoneItem.id;
    }
  }

  setApplicantTimezone(timezone) {
    this.applicantTimeZone = timezone;
  }
}
