import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-success-payment',
  templateUrl: './success-payment.component.html',
  styleUrls: ['./success-payment.component.scss']
})
export class SuccessPaymentComponent implements OnInit {

  transactionId: string = sessionStorage.getItem('transactionId');
  token: string = sessionStorage.getItem('token');
  constructor(private router: Router) {
  }

  ngOnInit() { }

  navigateToDashboard() {
    this.router.navigate([`/dashboard/application/${this.transactionId}/${this.token}`]);
  }
}
