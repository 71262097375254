import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SpinnerService } from 'src/app/shared/services/spinner.service';

@Component({
  selector: 'app-credit-card-main',
  templateUrl: './credit-card-main.component.html',
  styleUrls: ['./credit-card-main.component.scss']
})
export class CreditCardMainComponent implements OnInit {
    showSpinner: boolean;
    constructor(private spinnerService: SpinnerService) {}
    ngOnInit() {
        this.spinnerService
            .spinnerVisible$
            .subscribe(val => this.showSpinner = val);
    }
}
