<div class="container success-container">
    <div class="grid">
        <div class="col-6">
            <img class="success-image" src="./assets/images/Group 161624.png">
        </div>
        <div class="col-6 pl-3 flex align-items-stretch flex-wrap">
            <div class="pt-5 text-left">
                <div class="message-1">Thank You</div>
                <div class="message-2 pt-2">For updating your Credit Card information</div>
                <div class="message-3 pt-2">The information has been successfully saved within <span class="message-trust-commerce">TrustCommerce</span></div> 
            </div>
            <div class="align-self-end">
                <div class="pb-5">
                    <p-button label="Click here to be redirected" [link]="true" (click)="navigateToDashboard()"></p-button>
                </div>
            </div>
        </div>
    </div>
</div>