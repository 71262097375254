import { Injectable } from "@angular/core";


import { ActivatedRouteSnapshot } from "@angular/router";
import { UserIdentityService } from "./user-identity.service";
import * as _ from 'lodash';
import { QueryParameters } from "src/app/reschedule/models/enums";
import { UtilityService } from "../utility.service";

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    constructor(
        private utilityService: UtilityService)
    {}

    processAgentAuthentication() {
        return false;
    }

    processVerifierAccount(route: ActivatedRouteSnapshot) {
        let canActivate = false;
        let token = _.get(route.queryParams, QueryParameters.TOKEN, "");
        if(token === "") {
            this.utilityService.showNoTokenError();
        }
        else {
            localStorage.setItem("vcall-accessToken", token);
            canActivate = true;
        }
        return Promise.resolve(canActivate);
    }
}