import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-empty',
  template: ''
})
export class EmptyComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {}
}