import { Component, OnInit, OnDestroy} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SpinnerService } from '../../services/spinner.service';
import {
  MsalBroadcastService,
  MsalGuardConfiguration,
  MSAL_GUARD_CONFIG,
  MsalService,
} from '@azure/msal-angular';
import { Subject, filter, takeUntil } from 'rxjs';
import {
  EventMessage,
  EventType,
  InteractionStatus,
  RedirectRequest,
} from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';
import { UtilityService } from '../../services/utility.service';

@Component({
  selector: 'app-auth-main',
  templateUrl: './auth-main.component.html',
  styleUrls: ['./auth-main.component.scss']
})
export class AuthMainComponent implements OnInit, OnDestroy {
  showSpinner: boolean;
  postLoginPerformed = false;
  private readonly destroying$ = new Subject<void>();

  constructor(
    private spinnerService: SpinnerService,
    private msalBroadcastService: MsalBroadcastService,
    private msalService: MsalService,
    private route: ActivatedRoute,
    private utilityService: UtilityService
  ){}

  ngOnInit() {
    this.spinnerService.spinnerVisible$.subscribe(val => this.showSpinner = val);
  }

  get isIframe() {
    return this.utilityService.isIFrameMode();   
  }

  initMsalBroadcast() {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this.destroying$)
      )
      .subscribe(() => {
        this.postLogin();
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_SUCCESS ||
            msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
        ),
        takeUntil(this.destroying$)
      )
      .subscribe((result) => {
        this.postLogin();
      });
  }
  async postLogin() {
    if (this.postLoginPerformed) {
      return;
    }
    const account = this.msalService.instance.getAllAccounts()[0];
    this.msalService.instance.setActiveAccount(account);
    this.postLoginPerformed = true;
  }

  logout(popup?: boolean) {
    if (popup) {
      this.msalService.logoutPopup({
        mainWindowRedirectUri: '/',
      });
    }
    else {
      this.msalService.logoutRedirect();
    }
  }
  
  ngOnDestroy() {
    this.destroying$.next(null);
    this.destroying$.complete();
  }
}
