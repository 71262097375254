import { ParamMap } from "@angular/router";

export class BillingParameters {
    src: string;
    ushgAppName: string;
    ushgAppKey: string;
    ushgAppUser: string;
    ushgUseCase: string;
    ushgCompanyCode: string;
    ushgReferenceIdType: string;
    ushgReferenceId: string;
    nameOnCard?: string;
    respUrl: string;
}

export class BillingInfo {
    id: number;
    appId: number;
    transactionId: number;
    transactionStatusId: number;
    tcStatusText: string;
    applicantName: string;
    emailAddress: string;
    policyNumber: string;
    receivedDateTime: string;
}

export enum TransactionStatus {
    Created = 0,
    Pending = 1,
    Closed = 2, // complete
    Failed = 3,
    Retrying = 4,
    Timeout = 5
}

export class SelfServiceParams {
    applicationId: string;
    transactionId: string;
    constructor(params?: ParamMap) {
        if (params) {
            this.applicationId = params.get('applicationId');
            this.transactionId = params.get('transactionId');
        }
    }
}