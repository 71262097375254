import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HandledHttpClient } from './../shared/handledHttpClient';
import { BillingInfo, BillingParameters } from './payment-model';

@Injectable({
  providedIn: 'root',
})
export class PaymentApiService {
  private baseUrl: string;

  constructor(
    private http: HandledHttpClient,
  ) {
    this.baseUrl = environment.config.apis.nbc;
  }

  getBillingParameters(transactionId: string): Observable<BillingParameters> {
    return this.http.get<BillingParameters>(this.baseUrl + `/billing/v1/configuration/${transactionId}`);
  }

  getBillingInfo(transactionId: string): Observable<BillingInfo> {
    return this.http.get<BillingInfo>(this.baseUrl + `/transaction/v1/${transactionId}`);
  }

  retryFailedTransaction(billingInfo: BillingInfo): Observable<any> {
    return this.http.post(this.baseUrl + `/billing/v1/sendemail/${billingInfo.policyNumber}/${billingInfo.appId}`, {
      applicantName: billingInfo.applicantName,
      emailAddress: billingInfo.emailAddress
    });
  }

  verifyUser(transactionId: string, token: string): Observable<any> {    
    return this.http.get(this.baseUrl + `/auth/v1/billing/verify/${transactionId}/${token}`,
    {
      errorMessage: `UnAuthorized transactionId = '${transactionId}'`,
    });
  }
}
