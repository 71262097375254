export class AppMode {
    public static IFRAME = "iframe";
}

export class QueryParameters {
    public static APPID = "appId";
    public static DATE = "date";
    public static LANGUAGE = "language";
    public static TIMEZONE = "timeZone";
    public static MODE = "mode";
    public static APPOINTMENTID = "appointmentId";
    public static TIMEZONENAME = "timeZoneName";
    public static USERTYPE = "userType";
    public static USERNAME = "userName";
    public static RESOURCEID = "resourceId";
    public static TOKEN = "token";
}