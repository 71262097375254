<div class="container error-page-container">
  <img
    src="/assets/images/loading.gif"
    class="d-block mx-auto"
    width="70"
    alt="Loading"
  />
  <h3 class="text-center">Loading...</h3>
  <div class="text-center">
    <img src="./assets/images/unauthorized.svg" alt="" width="100" class="mb-5">
    <p>Application could not find the authentication token,<br />
      redirecting to the US Health Group's Directory Services.</p> 
    </div>
</div>
