import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { AppMode, QueryParameters } from 'src/app/reschedule/models/enums';
import { environment } from 'src/environments/environment';
import { MessageService } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  constructor(
    private activatedRoute: ActivatedRoute,
    private messageService: MessageService) {}

  getRouteQueryParamValues(keys: string[]) {
    let queryParams = this.activatedRoute.snapshot.queryParams;
    let paramValuesObject = {};
    keys.forEach(key => {
        let value = _.get(queryParams, key, "");
        paramValuesObject[key] = value;
    });
    return paramValuesObject;
  }

  isIFrameMode() {
    return true;
    // let queryParamsValues = this.getRouteQueryParamValues([QueryParameters.MODE]);
    // let mode = _.get(queryParamsValues, QueryParameters.MODE, "");
    // return mode.toLowerCase() === AppMode.IFRAME;
  }
  getEnvironmentName() {
    let environmentName = _.get(environment, "name", "");
    return environmentName;
  }
  isEnvironmentNameEmpty() {
    let environmentName = _.get(environment, "name", "");
    return environmentName === "";
  }
  showNoTokenError() {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Access Token Not Found' });
  }
  showApiError(message) {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: message });
  }
  checkHttpErrorResponse(result) {
    if(result instanceof HttpErrorResponse ){
      throw {
        error: result.error,
        message: "Error on HTTP Request"
      }
    }
  }
}
