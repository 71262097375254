import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HandledHttpClient {
  constructor(
    public http: HttpClient,
  ) {}

  get<T>(
    url: string,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe?: 'body';
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
      errorMessage?: string;
      successMessage?: string;
      responseDecider?: string;
      excludeToken?: boolean;
      donotLog?: boolean;
      dontAddPermissions?: boolean;
      addToken?: boolean;
    }
  ): Observable<T> {
    let headers = new HttpHeaders();
    options = options ? options : {};

    if (options && options.errorMessage) {
      headers = headers.append('x-error-message', options.errorMessage);
    }

    if (options && options.successMessage) {
      headers = headers.append('x-success-message', options.successMessage);
    }

    if (options && options.responseDecider) {
      headers = headers.append(
        'x-response-decider-key',
        options.responseDecider
      );
    }

    if (options && options.excludeToken) {
      headers = headers.append(
        'x-exclude-token',
        options.excludeToken.toString()
      );
    }

    if (options && options.donotLog) {
      headers = headers.append('x-dont-log', options.donotLog.toString());
    }

    if (options && options.dontAddPermissions) {
      console.log('Client opted to not add the permissions header');
    } else {
      //NOTE 1/11/24: This is hardcoding the permissions to the header. This will be revisted in the future.
      //const permissions = this.permissionService.PermissionSnapShotSerialized();
      headers = headers.append('x-permissions', "MembershipVCallRead");
    }

    if(options && options.addToken)
    {
      let token = localStorage.getItem("vcall-accessToken");
      headers = headers.append("Authorization", "Bearer " + token);
    }

    options.headers = headers;

    return this.http.get<T>(url, options);
  }

  post<T>(
    url: string,
    body: any | null,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe?: 'body';
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
      errorMessage?: string;
      successMessage?: string;
      responseDecider?: string;
      excludeToken?: boolean;
      donotLog?: boolean;
      dontAddPermissions?: boolean;
      addToken?: boolean;
    }
  ): Observable<T> {
    let headers = new HttpHeaders();
    options = options ? options : {};

    if (options && options.errorMessage) {
      headers = headers.append('x-error-message', options.errorMessage);
    }

    if (options && options.successMessage) {
      headers = headers.append('x-success-message', options.successMessage);
    }

    if (options && options.responseDecider) {
      headers = headers.append(
        'x-response-decider-key',
        options.responseDecider
      );
    }

    if (options && options.excludeToken) {
      headers = headers.append(
        'x-exclude-token',
        options.excludeToken.toString()
      );
    }

    if (options && options.donotLog) {
      headers = headers.append('x-dont-log', options.donotLog.toString());
    }
    
    if (options && options.dontAddPermissions) {
      console.log('Client opted to not add the permissions header');
    } else {
      //NOTE 1/11/24: This is hardcoding the permissions to the header. This will be revisted in the future.
      //const permissions = this.permissionService.PermissionSnapShotSerialized();
      headers = headers.append('x-permissions', "MembershipVCallRead");
    }

    if(options && options.addToken)
    {
      let token = localStorage.getItem("vcall-accessToken");
      headers = headers.append("Authorization", "Bearer " + token);
    }

    options.headers = headers;

    return this.http.post<T>(url, body, options);
  }

  delete<T>(
    url: string,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe?: 'body';
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
      errorMessage?: string;
      successMessage?: string;
      responseDecider?: string;
      excludeToken?: boolean;
    }
  ): Observable<T> {
    let headers = new HttpHeaders();
    options = options ? options : {};

    if (options && options.errorMessage) {
      headers = headers.append('x-error-message', options.errorMessage);
    }
    if (options && options.successMessage) {
      headers = headers.append('x-success-message', options.successMessage);
    }

    if (options && options.responseDecider) {
      headers = headers.append(
        'x-response-decider-key',
        options.responseDecider
      );
    }

    if (options && options.excludeToken) {
      headers = headers.append(
        'x-exclude-token',
        options.excludeToken.toString()
      );
    }

    options.headers = headers;

    return this.http.delete<T>(url, options);
  }

  put<T>(
    url: string,
    body: any | null,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe?: 'body';
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
      errorMessage?: string;
      successMessage?: string;
      responseDecider?: string;
      excludeToken?: boolean;
    }
  ): Observable<T> {
    let headers = new HttpHeaders();
    options = options ? options : {};
    if (options && options.errorMessage) {
      headers = headers.append('x-error-message', options.errorMessage);
    }
    if (options && options.successMessage) {
      headers = headers.append('x-success-message', options.successMessage);
    }

    if (options && options.responseDecider) {
      headers = headers.append(
        'x-response-decider-key',
        options.responseDecider
      );
    }

    if (options && options.excludeToken) {
      headers = headers.append(
        'x-exclude-token',
        options.excludeToken.toString()
      );
    }

    options.headers = headers;

    return this.http.put<T>(url, body, options);
  }
}
