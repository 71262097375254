import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-return-payment',
  templateUrl: './return-payment.component.html',
  styleUrls: ['./return-payment.component.scss']
})
export class ReturnPaymentComponent implements OnInit {

  constructor() { }

  ngOnInit() {}
  
}
