import { Component, OnInit } from '@angular/core';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { UtilityService } from 'src/app/shared/services/utility.service';

@Component({
  selector: 'app-reschedule-main',
  templateUrl: './reschedule-main.component.html',
  styleUrls: ['./reschedule-main.component.scss']
})
export class RescheduleMainComponent implements OnInit {
  showSpinner: boolean;
  constructor(
    private spinnerService: SpinnerService,
    private utilityService: UtilityService
  ) {}

  get isIframe() {
    return this.utilityService.isIFrameMode();   
  }

  ngOnInit() {
    this.spinnerService.spinnerVisible$.subscribe(val => this.showSpinner = val);
  }
}
